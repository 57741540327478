// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Arc from "https://framerusercontent.com/modules/4S4SnR1GdEzX95H1h5mT/2ycm7HJhFdePAmCNAAJd/Arc.js";
const ArcFonts = getFonts(Arc);

const cycleOrder = ["XbtnQvuJU", "BoGXacW86"];

const serializationHash = "framer-spRTu"

const variantClassNames = {BoGXacW86: "framer-v-5bg4cs", XbtnQvuJU: "framer-v-1mtkf2c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transition2 = {delay: 0, duration: 1, ease: [0.35, 0, 0, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "XbtnQvuJU", "Variant 2": "BoGXacW86"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XbtnQvuJU"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XbtnQvuJU", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({BoGXacW86: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1mtkf2c", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XbtnQvuJU"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BoGXacW86: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-3fo7tr-container"} layoutDependency={layoutDependency} layoutId={"jV21WO5q6-container"} style={{opacity: 1}} variants={{BoGXacW86: {opacity: 0}}}><Arc alignmentBaseline={"center"} animate={false} animateDuration={5} animateEnd={150} animateStart={-50} color={"var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255))"} cylinderHeight={0} flip={false} font={{fontFamily: "\"GT Pressura Trial Rg\", \"GT Pressura Trial Rg Placeholder\", sans-serif", fontSize: "12px", letterSpacing: "0.5em", lineHeight: "1em"}} height={"100%"} id={"jV21WO5q6"} layoutId={"jV21WO5q6"} rotate rotateSpeed={5} slots={[]} startOffset={0} style={{height: "100%", width: "100%"}} text={"✦ ORANGE CHOWK ✦ 2024"} type={"circle"} width={"100%"} wordSpacing={9}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-spRTu.framer-1m3hrcn, .framer-spRTu .framer-1m3hrcn { display: block; }", ".framer-spRTu.framer-1mtkf2c { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-spRTu .framer-3fo7tr-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 100px); position: relative; width: 100px; z-index: 4; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-spRTu.framer-1mtkf2c { gap: 0px; } .framer-spRTu.framer-1mtkf2c > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-spRTu.framer-1mtkf2c > :first-child { margin-left: 0px; } .framer-spRTu.framer-1mtkf2c > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"BoGXacW86":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBNbIk1pA8: React.ComponentType<Props> = withCSS(Component, css, "framer-spRTu") as typeof Component;
export default FramerBNbIk1pA8;

FramerBNbIk1pA8.displayName = "arc container";

FramerBNbIk1pA8.defaultProps = {height: 100, width: 100};

addPropertyControls(FramerBNbIk1pA8, {variant: {options: ["XbtnQvuJU", "BoGXacW86"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerBNbIk1pA8, [{explicitInter: true, fonts: [{family: "GT Pressura Trial Rg", source: "custom", url: "https://framerusercontent.com/assets/LO7uvoFnSGGnn2dhuEBqXNHPug.woff2"}]}, ...ArcFonts], {supportsExplicitInterCodegen: true})